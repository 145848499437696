<template>
	<div class="banner">
		<div class="swiper-wrapper">
			<div class="swiper-slide" v-for="val in banner.item">
				<img class="bannerPic" :src="val.img" @click="gotoLink(val)" />
				<!-- <img class="bannerPic" src="../../../assets/sources/3.png"> -->
			</div>
		</div>
	</div>
</template>

<script>
	import Swiper from 'swiper';
	export default {
		name: 'BannerMob',
		props: {
			data: {
				type: Array,
				default: []
			}
		},
		data() {
			return {
				banner: {
					progress: 25,
					current: 0,
					size: 4,
					item: [1, 2, 3, 4]
				}
			};
		},
		watch: {
			data(res) {
				this.banner.item = res;
				var mySwiper = new Swiper('.banner', {
					loop: true
				});
			}
		},
		created() {
			// setTimeout(() => {
			// 	var mySwiper = new Swiper('.banner', {
			// 		loop: true,
			// 		pagination: {
			// 			el: '.swiper-pagination'
			// 		}
			// 	});
			// }, 1300);
		},
		methods: {
			gotoLink(item) {
				let pages = {
					category: "GoodsList?cateId=",
					index1: "GoodsList?cateId=",
					index2: "GoodsList?cateId=",
					index3: "GoodsList?cateId=",
					index4: "GoodsList?cateId=",
					index5: "GoodsList?cateId=",
					index6: "GoodsList?cateId=",
				};

				this.$router.push(`/${pages[item.linkType]}${item.linkId}`);
			},
		}
	};
</script>

<style lang="less" scoped>
	.banner {
		height: 260px;
		background: #ccc;
		position: relative;

		/deep/.el-carousel {
			height: 100%;

			.el-carousel__container {
				height: 100%;
			}
		}

		.bannerPic {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.indicator {
			position: absolute;
			z-index: 9;
			bottom: 47px;
			left: 80px;
			display: flex;
			align-items: center;

			.number {
				color: #fff;
				font-size: 16px;
				font-family: GillSans;
				font-weight: 600;
			}

			.progress {
				width: 264px;
				height: 2px;
				background: #ababab;
				border-radius: 1px;
				margin: 0 30px;
				position: relative;

				.val {
					position: absolute;
					z-index: 10;
					top: -1px;
					bottom: -1px;
					border-radius: 2px;
					background: #fff;
					left: 0;
					transition: all 0.3s;
				}
			}
		}

		.arrow {
			position: absolute;
			z-index: 9;
			right: 80px;
			bottom: 34px;

			&>i {
				color: #fff;
				font-size: 32px;
				font-weight: bold;
				margin-left: 45px;
				cursor: pointer;
			}

			&>i.disable {
				color: #ababab;
				cursor: not-allowed;
			}
		}
	}
</style>
